$background-white: hsl(0, 0%, 98%);

$color-primary: hsl(357, 68%, 35%);
$color-primary-light: hsl(357, 68%, 40%);
$color-primary-dark: hsl(357, 68%, 30%);
$color-orange: rgb(239,104,47);
$color-grey: rgb(71,71,71);
$color-grey-light: rgb(101,101,101);
$color-grey-dark: rgb(41,41,41);

$color-text-black: hsl(0, 0%, 5%);
$color-text: hsl(0, 0%, 25%);
$color-text-white: hsl(0, 0%, 100%);
$color-text-grey: hsl(0, 0%, 45%);
$color-text-error:  hsl(0, 82%, 50%);
$color-text-success: hsl(121, 87%, 35%);

$font-size-content: 1.2rem;

$logo-size: 33.333333vw;
$layout-size: min(90%, 90rem);
$layout-size-tablet: min(66.666666%, 66.666666rem);
$layout-size-desktop: min(33.333333%, 33.333333rem);


:root {
	font-size: 18px;
}

body {
	background-color: $background-white;
	color: $color-text;
	@include font(AkzidenzGrotesk);
}

#root {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	padding: 5rem 0 1rem;
	position: relative;
}

/* ================================================================= */
/* ============================= PAGE ============================== */
/* ================================================================= */
.main {
	flex-grow: 1;
	margin: 0 auto;
	width: $layout-size;

	&__header {
		margin-bottom: 1.5rem;
	}

	&__logo {
		align-items: center;
		display: flex;
		flex-direction: column;
		margin: 0 auto 1.5rem;

		& img {
			width: $logo-size;
			max-width: 200px;
		}

		&__text {
			@include font(QuantumLatin);
		}
	}

	&__title {
		color: $color-text-black;
		font-weight: bold;
		margin-bottom: 0.5rem;
	}

	&__text {
		font-size: 1rem;
		font-weight: 300;

		&--center {
			text-align: center;
		}

		&--success { color: $color-text-success }
		
		&--error { color: $color-text-error }
	}

	&__link {
		cursor: pointer;
		margin-bottom: 0.5rem;
		text-decoration: underline;

		&:hover {
			color: $color-primary-light;
		}
	}

	@media (min-width: 768px) {
		width: $layout-size-tablet;
	}

	@media (min-width: 1025px) {
		width: $layout-size-desktop;
	}
}

.account {
	align-items: center;
	cursor: pointer;
	display: flex;
	position: absolute;
	right: 1.5rem;
	top: 1.5rem;

	&__logo {
		margin-right: .5rem;
		width: 2rem;

		& svg {
			fill: $color-text-grey
		}
	}
	&__text {
		color: $color-text-grey;
	}

	&:hover {
		.account__text {
			text-decoration: underline;
		}
	}
}

.form__input {
	display: flex;
	flex-direction: column;
	margin-bottom: 0.5rem;

	& label, & .label {
		margin-bottom: 0.3rem;
	}

	& input, & .input {
		background-color: #ffffff;
		border: 2px solid hsl(0, 0%, 82%);
		border-radius: 5px;
		padding: 0.7rem;

		&:not([type="checkbox"]) {
			width: 100%;
		}
	
		&:focus, &:focus-visible {
			border-color: $color-primary !important;
			outline: none;
		}
	
		&--half {
			flex-basis: 50%
		}

		&:disabled, &--disabled {
			background-color: hsl(0, 0%, 82%);
			border-color: hsl(0, 0%, 77%);
			color: $color-text-grey;
			cursor: initial;

			& ~ .icon--eye { //TODO
				fill: $color-text-grey;
			}
		}

		&[type="checkbox"] {
			margin-right: .5rem;
		}
	}

	& .input__wrapper {
		margin-bottom: 0.2rem;
		position: relative;

		& .icon--eye {
			fill: $color-primary;
			position: absolute;
			right: 0.5rem;
			height: 1.8rem;
			top: 50%;
			transform: translateY(-50%);
		}

		&--inline { 
			display: flex;
			flex-direction: row;

			& > * {
				margin-bottom: 0;

				&:first-child {
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
				}

				&:last-child {
					border-top-left-radius: 0;
					border-bottom-left-radius: 0;
				}
			}

			& > button {
				flex-basis: 33.333333%;
			}
		}
	}

	&__error {
		color: $color-text-error;
		margin-left: 0.7rem;
	}

	&--stripe {
		& .--first {
			border-bottom-width: 0.5px;
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
			margin-bottom: 0;
		}
	}
	
	&--inline {
		display: flex;
		flex-direction: row;
		justify-content: flex-start;

		& > input:not([type="checkbox"]), & > .input {
			border-top-width: 0.5px;
			
			&:first-child {
				border-right-width: 0.5px;
				border-bottom-left-radius: 5px;
				border-bottom-right-radius: 0;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
			}
			
			&:last-child {
				border-left-width: 0.5px;
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 5px;
				border-top-left-radius: 0;
				border-top-right-radius: 0;
			}
	
			&:not(:first-child, :last-child) {
					border-radius: 0;
					border-right-width: 0.5px;
					border-left-width: 0.5px;
			}
		}
	}

	&--separator {
		margin-bottom: 2rem;
	}
}

/* ================================================================= */
/* ============================= BUTTON ============================ */
/* ================================================================= */
button, .button {
	align-items: center;
	background: $color-primary;
	border-radius: 5px;
	color: $color-text-white;
	display: flex;
	justify-content: center;
	margin-bottom: 0.5rem;
	padding: 0.7rem;
	width: 100%;

	&:hover {
		background: $color-primary-light;
	}
	
	&:active {
		background: $color-primary-dark;
	}
	
	&:disabled {
		background: $color-grey;
		color: $color-grey-light;
		cursor: initial;
	}

	&__wrapper {
		display: flex;

		&--inline {
			flex-direction: row;
		}
	}
	&--secondary { 
		background: $color-grey; 
		
		&:hover {
			background: $color-grey-light; 
		}
	}
}

/* ================================================================= */
/* ============================= PROFILE =========================== */
/* ================================================================= */
.profile {
	flex-grow: 1;
	margin: 0 auto;
	width: $layout-size;

	&__header {
	}

	&__logo {
		align-items: center;
		display: flex;
		flex-direction: column;
		margin: 0 auto 1.5rem;

		& img {
			width: $logo-size;
			max-width: 200px;
		}
	}
	
	&__content {
		display: flex;
		flex-direction: column;

		&__navigation {
			display: flex;
			justify-content: space-between;
			margin-bottom: 1.5rem;

			&__item {
				border-bottom: 1px solid #000;
				color: $color-primary;
				display: flex;
				align-items: center;
				cursor: pointer;
				padding: 0.8rem;
				text-align: center;

				&:hover:not(&--active) {
					color: $color-primary-light;
				}

				&:not(:last-child) { margin-right: 1rem; }

				&--active {
					color: $color-text-grey;
				}
			}
		}
		&__data {
		}

		&__title {
			font-weight: 700;
			margin-bottom: 1.5rem;
		}

		& .separator {
			border-bottom: 1px solid black;
			margin: 1rem 0;
		}

		&__link {
			color: $color-primary;
			cursor: pointer;
			margin: 2rem 0;
			text-decoration: underline;

			&:hover {
				color: $color-primary-light;
			}
		}
	}

	&__subscription {
		&__section {
			color: $color-text-grey;
			margin-bottom: 2rem;
			
			&__title {
				margin-bottom: 0.5rem;
				font-weight: 700;
			}

			&__text {}
		}
	}

	@media (min-width: 768px) {
		width: $layout-size-tablet;
	}

	@media (min-width: 1025px) {
		width: $layout-size-desktop;
	}
}

/* ================================================================= */
/* ============================= ICON ============================== */
/* ================================================================= */
.icon {
	cursor: pointer;

	&--disabled { 
		cursor: initial;
	}
}

/* ================================================================= */
/* ============================= FOOTER ============================ */
/* ================================================================= */
.footer {
	border-top: 1px solid #000;
	margin: 0 auto;
	padding: 1.5rem calc((100vw - $layout-size) / 2) 0;
	width: 100%;


	&__nav {
		display: flex;
		flex-direction: column;

		&__item {
			margin-bottom: 0.5rem;

			&__link {
				color: $color-text-grey;
				cursor: pointer;

				&:hover {
					text-decoration: underline;
				}
			}
		}
	}

	@media (min-width: 768px) {
		// padding: 1.5rem calc((100vw - $layout-size-tablet) / 2) 0;
		&__nav {
			flex-direction: row;
			justify-content: center;

			&__item {
				margin-right: 2rem;
			}
		}	
	}

	@media (min-width: 1025px) {
		// padding: 1.5rem calc((100vw - $layout-size-desktop) / 2) 0;
	}
}

/* ================================================================= */
/* ============================= ???? ============================== */
/* ================================================================= */
// TODO keep or del ? 
.subscription {
	&__list {
		margin-bottom: 0.5rem;
	}

	&__item {
		background-color: #ffffff;
		border: 2px solid #D1D1D1;
		border-radius: 5px;
		display: flex;
		padding: 0.7rem;
	
		&__selector {
			flex-basis: 5%;
			margin-right: 0.7rem;
			position: relative;

			& .selector {
				border: 1px solid #D1D1D1;
				border-radius: 50%;
				height: 16px;
				left: 50%;
				position: absolute;
				top: 50%;
				transform: translate(-50%, -50%);
				width: 16px;

				&:after {
					background: $color-primary;
					border-radius: 50%;
					content: "";
					height: 10px;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);	
					width: 10px;
				}
			}
		}

		&__data {
			flex-grow: 1;
		}
		
		&__duration {}

		&__price {
			& .price--crossed {
				color:$color-text-grey;
				display: inline-block;
				margin-right: 0.5rem;
				// text-decoration: line-through;
				position: relative;

				&::after {
					background: linear-gradient(to top left,
						rgba(0,0,0,0) 0%,
						rgba(0,0,0,0) calc(50% - 0.8px),
						rgba(0,0,0,1) 50%,
						rgba(0,0,0,0) calc(50% + 0.8px),
						rgba(0,0,0,0) 100%);
					content: "";
					height: 90%;
					top: 5%;
					left: 0;
					position: absolute;
					width: 100%;
				}
			}
		}

		&__billing {}
	}
}
